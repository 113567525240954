export const projects = [
  {
    id: 1,
    title: "Lava Parkour: Muhittin's Adventure",
    description: "Unreal Engine ile geliştirilmiş 3D platform oyunu. Lava parkour mücadelesi",
    image: "/images/Muhittin/0RwHN.jpg",
    technologies: ["Unreal Engine", "Blueprint", "3D Graphics"],
    gallery: [
      "/images/Muhittin/0RwHN.jpg",
      "/images/Muhittin/6oqZr.jpg"
    ],
    longDescription: "Lava Parkour: Muhittin's Adventure, muhittin adında bir karakterin, lava parkour mücadelesinde hayatta kalmaya çalıştığı bir oyundur.",
    videoUrl: "https://www.youtube.com/embed/wziPQBuIiQw",
    details: {
      geliştirmeSüresi: "1 hafta",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "1 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "UI/UX"],
      özellikler: [
        
      ]
    },
    gameUrl: "https://irunboss.itch.io/lavaparkour"
  },
  {
    id: 2,
    title: "Bum Bum Monsterz",
    description: "Unity ile geliştirilmiş 3D party oyunu. Bıngıl Bıngıl eğlence.",
    image: "/images/Bum Bum Monsterz/Bumheader.jpg",
    technologies: ["Unity", "C#"],
    gallery: [
      "/images/Bum Bum Monsterz/Bum1.jpg",
      "/images/Bum Bum Monsterz/Bum2.jpg",
      "/images/Bum Bum Monsterz/Bum3.jpg",
      "/images/Bum Bum Monsterz/Bum4.jpg",
      "/images/Bum Bum Monsterz/Bum5.jpg",
    ],
    longDescription: "Bum Bum Monsterz, 2v2'den 4v4'e kadar oynanabilen takım tabanlı çok oyunculu bir parti oyunudur. Arkadaşlarınızı gırgır dolu mücadelelere girin, absürt engellerle başa çıkmaya çalışın ve eğlenceli bulmacalar çözün. Canlı grafikler ve renkli karakterlerle bıngıl bıngıl bir maceraya hazır mısınız?",
    videoUrl: "https://www.youtube.com/embed/Z8L_kzEPoxg",
    details: {
      geliştirmeSüresi: "5 ay",
      platform: "PC",
      durumu: "Erken Erişim",
      ekipBüyüklüğü: "-",
      roller: ["Bug Fix", "Programlama"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Özelleştirilebilir karakterler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/2071400/Bum_Bum_Monsterz/"
  },
  {
    id: 3,
    title: "Dream Diver",
    description: "Unity ile geliştirilmiş 3D FPS gamejam oyunu",
    image: "/images/Dream Diver/1.jpg",
    technologies: ["Unity", "C#",],
    gallery: [
      "/images/Dream Diver/1.jpg",
      "/images/Dream Diver/2.jpg",
      "/images/Dream Diver/3.jpg",
      "/images/Dream Diver/4.jpg",
      "/images/Dream Diver/5.jpg",
      "/images/Dream Diver/6.jpg",
    ],
    longDescription: "Dream Diver, Konusu derine dalmak olan bir Brackeys Game Jam 2023.2 oyunudur. Oyunun amacı, rüyalarda derinlere dalarak düşmanları yok etmektir.",
    videoUrl: "https://www.youtube.com/embed/-eq8coMW5hg",
    details: {
      geliştirmeSüresi: "1 hafta",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "UI/UX","Level Design"],
      özellikler: [
        "FPS oyun mekanikleri",
        "Düşman yapay zekası",
      ]
    },
    gameUrl: "https://emircanisanc.itch.io/dream-diver"
  },
 /* {
    id: 4,
    title: "Racing Fever",
    description: "Unreal Engine ile geliştirilmiş yarış oyunu. Gerçekçi fizik motoru",
    image: "/images/racing-game.jpg",
    technologies: ["Unreal Engine", "C++", "Physics"],
    gallery: [
      "/images/racing-game/1.jpg",
      "/images/racing-game/2.jpg",
      "/images/racing-game/3.jpg",
      "/images/racing-game/4.jpg"
    ],
    longDescription: "Racing Fever, gerçekçi fizik motoru ile tasarlanmış bir yarış oyunudur. Oyuncular, farklı araçları kullanarak yarışları yaparken hız ve performansı optimize ederler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_4",
    details: {
      geliştirmeSüresi: "5 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Fizik"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir araçlar"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 5,
    title: "Survival Island",
    description: "Unity ile geliştirilmiş hayatta kalma oyunu. Açık dünya macerası",
    image: "/images/survival-game.jpg",
    technologies: ["Unity", "C#", "AI"],
    gallery: [
      "/images/survival-game/1.jpg",
      "/images/survival-game/2.jpg",
      "/images/survival-game/3.jpg",
      "/images/survival-game/4.jpg"
    ],
    longDescription: "Survival Island, açık dünya macerası olarak tasarlanmış bir hayatta kalma oyunudur. Oyuncular, çeşitli zorluklarla karşılaşırken hayatta kalmayı amaçlar.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_5",
    details: {
      geliştirmeSüresi: "7 ay",
      platform: "PC, Mobile",
      durumu: "Devam ediyor",
      ekipBüyüklüğü: "4 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Yapay Zeka"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir karakterler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 6,
    title: "Medieval Wars",
    description: "Unreal Engine ile geliştirilmiş strateji oyunu. Krallıklar arası savaş",
    image: "/images/strategy-game.jpg",
    technologies: ["Unreal Engine", "C++", "Multiplayer"],
    gallery: [
      "/images/strategy-game/1.jpg",
      "/images/strategy-game/2.jpg",
      "/images/strategy-game/3.jpg",
      "/images/strategy-game/4.jpg"
    ],
    longDescription: "Medieval Wars, krallıklar arası savaşı simüle eden bir strateji oyunudur. Oyuncular, farklı krallıkları kontrol ederken stratejiler geliştirirler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_6",
    details: {
      geliştirmeSüresi: "9 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "5 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Çoklu Oyuncu"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir krallıklar"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 7,
    title: "Zombie Defense",
    description: "Unity ile geliştirilmiş tower defense oyunu. Zombi istilasına karşı savunma",
    image: "/images/zombie-game.jpg",
    technologies: ["Unity", "C#", "Pathfinding"],
    gallery: [
      "/images/zombie-game/1.jpg",
      "/images/zombie-game/2.jpg",
      "/images/zombie-game/3.jpg",
      "/images/zombie-game/4.jpg"
    ],
    longDescription: "Zombie Defense, zombi istilasına karşı savunma olarak tasarlanmış bir tower defense oyunudur. Oyuncular, zombileri engellemek ve koruma için çeşitli savunma yapıları inşa ederler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_7",
    details: {
      geliştirmeSüresi: "8 ay",
      platform: "PC, Mobile",
      durumu: "Devam ediyor",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Yol Bulma"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir zombiler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 8,
    title: "Sports League",
    description: "Unreal Engine ile geliştirilmiş spor simülasyonu. Gerçekçi fizik ve animasyonlar",
    image: "/images/sports-game.jpg",
    technologies: ["Unreal Engine", "C++", "Animation"],
    gallery: [
      "/images/sports-game/1.jpg",
      "/images/sports-game/2.jpg",
      "/images/sports-game/3.jpg",
      "/images/sports-game/4.jpg"
    ],
    longDescription: "Sports League, gerçekçi fizik ve animasyonlarla tasarlanmış bir spor simülasyonu oyunudur. Oyuncular, farklı sporları simüle ederken fizik ve animasyon becerilerini geliştirirler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_8",
    details: {
      geliştirmeSüresi: "7 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "4 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Animasyon"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir sporlar"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 9,
    title: "City Builder",
    description: "Unity ile geliştirilmiş şehir inşa etme oyunu. Ekonomi ve kaynak yönetimi",
    image: "/images/city-game.jpg",
    technologies: ["Unity", "C#", "Economy System"],
    gallery: [
      "/images/city-game/1.jpg",
      "/images/city-game/2.jpg",
      "/images/city-game/3.jpg",
      "/images/city-game/4.jpg"
    ],
    longDescription: "City Builder, ekonomi ve kaynak yönetimi için tasarlanmış bir şehir inşa etme oyunudur. Oyuncular, şehirlerini inşa ederken ekonomi ve kaynak yönetimi hakkında bilgi edinirler.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_9",
    details: {
      geliştirmeSüresi: "10 ay",
      platform: "PC",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "5 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "Ekonomi"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir şehirler"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  },
  {
    id: 10,
    title: "VR Experience",
    description: "Unreal Engine ile geliştirilmiş sanal gerçeklik deneyimi. İnteraktif ortam",
    image: "/images/vr-game.jpg",
    technologies: ["Unreal Engine", "C++", "VR"],
    gallery: [
      "/images/vr-game/1.jpg",
      "/images/vr-game/2.jpg",
      "/images/vr-game/3.jpg",
      "/images/vr-game/4.jpg"
    ],
    longDescription: "VR Experience, sanal gerçeklik deneyimi olarak tasarlanmış bir oyunudur. Oyuncular, sanal ortamda interaktif deneyimler yaşarlar.",
    videoUrl: "https://www.youtube.com/embed/VIDEO_ID_10",
    details: {
      geliştirmeSüresi: "8 ay",
      platform: "PC, Mobile",
      durumu: "Tamamlandı",
      ekipBüyüklüğü: "3 kişi",
      roller: ["Oyun Tasarımı", "Programlama", "VR"],
      özellikler: [
        "Çoklu oyuncu desteği",
        "Kaydetme sistemi",
        "Başarım sistemi",
        "Özelleştirilebilir sanal gerçeklik"
      ]
    },
    gameUrl: "https://store.steampowered.com/app/XXXX/Game_Name"
  }*/
]; 