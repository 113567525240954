import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useParams, useNavigate } from 'react-router-dom';
import { projects } from '../data/projects';

function ProjectDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const project = projects.find(p => p.id === parseInt(id));
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleGoBack = () => {
    navigate('/');
    setTimeout(() => {
      const projectsSection = document.getElementById('projects');
      projectsSection?.scrollIntoView({ behavior: 'smooth' });
    }, 100);
  };

  if (!project) {
    return navigate;
  }

  // Video ve resimleri birleştir (video ilk sırada)
  const allMedia = [];
  if (project.videoUrl) {
    allMedia.push(project.videoUrl);
  }
  allMedia.push(...project.gallery);

  const isCurrentItemVideo = currentImageIndex === 0 && project.videoUrl;

  const nextImage = () => {
    setCurrentImageIndex((prev) => 
      prev === allMedia.length - 1 ? 0 : prev + 1
    );
  };

  const previousImage = () => {
    setCurrentImageIndex((prev) => 
      prev === 0 ? allMedia.length - 1 : prev - 1
    );
  };

  // Video thumbnail URL'sini oluşturan yardımcı fonksiyon
  const getVideoThumbnail = (videoUrl) => {
    const videoId = videoUrl.split('/').pop();
    return `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
  };

  // Video URL'sini düzenleyen yardımcı fonksiyon
  const getVideoUrl = (url) => {
    const videoId = url.split('/').pop();
    return `https://www.youtube.com/embed/${videoId}?controls=1&rel=0`;
  };

  return (
    <section className="min-h-screen py-16 bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.button
          onClick={handleGoBack}
          className="mb-8 text-gray-400 hover:text-white flex items-center gap-2 z-10 relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" />
          </svg>
          Geri Dön
        </motion.button>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          {/* Sol Taraf - Galeri ve Video */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800 rounded-xl overflow-hidden relative"
          >
            <div className="relative aspect-video">
              {isCurrentItemVideo ? (
                <div className="w-full h-full relative z-0">
                  <iframe
                    src={getVideoUrl(allMedia[currentImageIndex])}
                    title={`${project.title} Gameplay`}
                    className="w-full h-full"
                    frameBorder="0"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              ) : (
                <motion.img
                  key={currentImageIndex}
                  src={allMedia[currentImageIndex]}
                  alt={`${project.title} - Görsel ${currentImageIndex}`}
                  className="w-full h-full object-contain bg-black"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.3 }}
                  loading="eager"
                  decoding="async"
                />
              )}
            </div>

            <div className="relative p-2">
              <div className="flex items-center gap-2">
                <motion.button
                  onClick={previousImage}
                  className="p-3 rounded-full bg-gray-800 text-gray-400 hover:text-white border border-gray-700 hover:border-purple-500/50 hover:shadow-lg hover:shadow-purple-500/20 transition-all duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 w-5" 
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    <path 
                      fillRule="evenodd" 
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" 
                      clipRule="evenodd" 
                    />
                  </svg>
                </motion.button>
                
                <div className="flex-1 overflow-hidden">
                  <div className="grid grid-cols-4 gap-2">
                    {allMedia.map((item, index) => (
                      <motion.div
                        key={index}
                        className={`aspect-video rounded-lg overflow-hidden cursor-pointer relative ${
                          currentImageIndex === index ? 'ring-2 ring-purple-500' : ''
                        }`}
                        onClick={() => setCurrentImageIndex(index)}
                        whileHover={{ scale: 1.05 }}
                      >
                        {index === 0 && project.videoUrl ? (
                          <div className="relative w-full h-full">
                            <img
                              src={getVideoThumbnail(project.videoUrl)}
                              alt={`${project.title} - Video Önizleme`}
                              className="w-full h-full object-cover"
                              loading="lazy"
                              decoding="async"
                            />
                            <div className="absolute inset-0 flex items-center justify-center">
                              <div className="relative group">
                                <div className="absolute -inset-4 bg-gradient-to-r from-purple-600 to-blue-500 rounded-full blur opacity-30 group-hover:opacity-60 transition duration-300"></div>
                                <div className="relative bg-black/50 backdrop-blur-sm p-4 rounded-full transform group-hover:scale-110 transition-all duration-300 border border-white/10">
                                  <svg 
                                    xmlns="http://www.w3.org/2000/svg" 
                                    viewBox="0 0 24 24" 
                                    fill="currentColor" 
                                    className="w-6 h-6 text-white group-hover:text-purple-300 transition-colors"
                                  >
                                    <path 
                                      fillRule="evenodd" 
                                      d="M4.5 5.653c0-1.427 1.529-2.33 2.779-1.643l11.54 6.347c1.295.712 1.295 2.573 0 3.286L7.28 19.99c-1.25.687-2.779-.217-2.779-1.643V5.653Z" 
                                      clipRule="evenodd" 
                                    />
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <img
                            src={item}
                            alt={`${project.title} - Küçük Görsel ${index}`}
                            className="w-full h-full object-cover"
                            loading="lazy"
                            decoding="async"
                          />
                        )}
                      </motion.div>
                    ))}
                  </div>
                </div>

                <motion.button
                  onClick={nextImage}
                  className="p-3 rounded-full bg-gray-800 text-gray-400 hover:text-white border border-gray-700 hover:border-purple-500/50 hover:shadow-lg hover:shadow-purple-500/20 transition-all duration-300"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    className="h-5 w-5" 
                    viewBox="0 0 20 20" 
                    fill="currentColor"
                  >
                    <path 
                      fillRule="evenodd" 
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" 
                      clipRule="evenodd" 
                    />
                  </svg>
                </motion.button>
              </div>
            </div>
          </motion.div>

          {/* Sağ Taraf - Proje Detayları */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-gray-800 rounded-xl p-6"
          >
            <h1 className="text-3xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400">
              {project.title}
            </h1>
            <p className="text-gray-300 mb-6">{project.longDescription}</p>
            
            {project.gameUrl && (
              <motion.a
                href={project.gameUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block mb-6 px-6 py-3 bg-purple-600 hover:bg-purple-700 rounded-lg text-white font-semibold transition-all duration-300 transform hover:scale-105"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <div className="flex items-center gap-2">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clipRule="evenodd" />
                  </svg>
                  Oyunu Oyna
                </div>
              </motion.a>
            )}
            
            <div className="grid grid-cols-2 gap-4 mb-6">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 mb-2">Geliştirme Süresi</h3>
                <p className="text-white">{project.details.geliştirmeSüresi}</p>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-400 mb-2">Platform</h3>
                <p className="text-white">{project.details.platform}</p>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-400 mb-2">Durum</h3>
                <p className="text-white">{project.details.durumu}</p>
              </div>
              <div>
                <h3 className="text-sm font-semibold text-gray-400 mb-2">Ekip Büyüklüğü</h3>
                <p className="text-white">{project.details.ekipBüyüklüğü}</p>
              </div>
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-3 text-white">Teknolojiler</h3>
              <div className="flex flex-wrap gap-2">
                {project.technologies.map((tech) => (
                  <span 
                    key={tech}
                    className="bg-purple-600/30 text-purple-300 px-3 py-1 rounded-full text-sm border border-purple-500/30"
                  >
                    {tech}
                  </span>
                ))}
              </div>
            </div>

            <div className="mb-6">
              <h3 className="text-lg font-semibold mb-3 text-white">Roller</h3>
              <div className="flex flex-wrap gap-2">
                {project.details.roller.map((rol) => (
                  <span 
                    key={rol}
                    className="bg-blue-600/30 text-blue-300 px-3 py-1 rounded-full text-sm border border-blue-500/30"
                  >
                    {rol}
                  </span>
                ))}
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-3 text-white">Özellikler</h3>
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                {project.details.özellikler.map((özellik) => (
                  <li key={özellik}>{özellik}</li>
                ))}
              </ul>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}

export default ProjectDetail;