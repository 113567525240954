import React, { useState, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { socialLinks } from '../data/socialLinks';

function Hero() {
  const [activeSection, setActiveSection] = useState('home');
  
  const navItems = [
    { id: 'home', text: 'Ana Sayfa', icon: '🏠' },
    { id: 'about', text: 'Hakkımda', icon: '👨‍💻' },
    { id: 'skills', text: 'Yetenekler', icon: '🚀' },
    { id: 'projects', text: 'Projeler', icon: '🎮' },
    { id: 'contact', text: 'İletişim', icon: '✉️' }
  ];

  useEffect(() => {
    const handleScroll = () => {
      const sections = ['home', 'about', 'skills', 'projects', 'contact'];
      
      for (const section of sections) {
        const element = document.getElementById(section);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (rect.top <= 150 && rect.bottom >= 150) {
            setActiveSection(section);
            break;
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [ref, inView] = useInView({
    threshold: 0.1,
    triggerOnce: false
  });

  const controls = useAnimation();

  useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        duration: 0.5,
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: { duration: 0.5 }
    }
  };

  return (
    <section id="home" className="min-h-screen relative overflow-hidden bg-gradient-to-b from-gray-900 to-purple-900">
      <div className="absolute inset-0 bg-grid-pattern bg-grid opacity-20"></div>
      
      <motion.nav 
        className="fixed left-8 top-1/2 -translate-y-1/2 z-50 hidden md:block"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <div className="rounded-full py-4 px-3">
          <ul className="space-y-6">
            {navItems.map((item) => (
              <motion.li 
                key={item.id}
                variants={itemVariants}
                whileHover={{ x: 5 }}
                whileTap={{ scale: 0.95 }}
              >
                <a
                  href={`#${item.id}`}
                  className="flex items-center justify-center group relative"
                >
                  <span 
                    className={`w-3 h-3 rounded-full transition-all duration-300
                      ${activeSection === item.id 
                        ? 'bg-primary-400 scale-125' 
                        : 'bg-gray-600 group-hover:bg-gray-400'}`}
                  />
                  <span 
                    className={`absolute left-full ml-4 px-3 py-2 rounded-lg 
                      bg-gray-800 text-sm whitespace-nowrap opacity-0 
                      group-hover:opacity-100 transition-opacity`}
                  >
                    {item.icon} {item.text}
                  </span>
                </a>
              </motion.li>
            ))}
          </ul>
        </div>
      </motion.nav>
      
      <div className="container mx-auto px-6 relative">
        <motion.div 
          ref={ref}
          className="flex flex-col items-center justify-center min-h-screen text-center"
          variants={containerVariants}
          initial="hidden"
          animate={controls}
        >
          <div className="flex flex-col items-center justify-center flex-grow">
            <motion.div 
              className="relative mb-8"
              variants={itemVariants}
            >
              <div className="w-48 h-48 md:w-56 md:h-56 rounded-full overflow-hidden border-4 border-blue-400/20 shadow-xl shadow-blue-500/10">
                <img 
                  src="/images/profile.jpg" 
                  alt="Nuri Onacak" 
                  className="w-full h-full object-cover hover:scale-105 transition-all duration-500"
                />
              </div>
              <div className="absolute inset-0 rounded-full bg-gradient-to-b from-blue-500/10 to-transparent"></div>
            </motion.div>

            <motion.h1 
              className="text-5xl md:text-7xl font-bold mb-6 pt-4 pb-2 bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 leading-relaxed"
              variants={itemVariants}
            >
              Nuri Onacak
            </motion.h1>
            
            <motion.div 
              className="space-y-4 mb-8"
              variants={itemVariants}
            >
              <motion.p 
                className="text-2xl md:text-3xl text-gray-300 font-medium tracking-wider"
              >
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-teal-400">
                  Game Developer
                </span>
              </motion.p>
            </motion.div>

            <motion.div 
              className="space-y-4"
              variants={itemVariants}
            >
              <motion.a
                href="#projects"
                className="inline-block px-6 py-3 bg-primary-500 rounded-full text-lg font-semibold hover:bg-primary-600 transition-all hover:scale-105 hover:shadow-lg hover:shadow-primary-500/30 mb-6"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Projelerimi Gör
              </motion.a>
            </motion.div>

            <motion.p
              className="text-sm text-gray-400 italic mb-4 block"
              variants={itemVariants}
            >
              ~ bolca sevgi ve kafeinle yapıldı ~
            </motion.p>

            <motion.div 
              className="flex gap-4 mb-16 md:mb-24 lg:mb-24"
              variants={itemVariants}
            >
              {socialLinks.map((link, index) => (
                <motion.a
                  key={link.url}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-2xl text-gray-400 ${link.color}`}
                  whileHover={{ scale: 1.2 }}
                >
                  <link.icon />
                </motion.a>
              ))}
            </motion.div>

            <motion.div 
              className="flex flex-col items-center text-gray-400 cursor-pointer group mb-8 md:mb-24"
              variants={itemVariants}
              onClick={() => {
                const aboutSection = document.getElementById('about');
                aboutSection?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              <span className="text-sm mb-2 group-hover:text-blue-400 transition-colors">
                Aşağı Kaydır
              </span>
              <motion.div
                animate={{
                  y: [0, 10, 0],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-6 w-6 group-hover:text-blue-400 transition-colors"
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M19 14l-7 7m0 0l-7-7m7 7V3" 
                  />
                </svg>
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
}

export default Hero; 