import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { projects } from '../data/projects';

function Projects() {
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = window.innerWidth < 640 ? 6 : 6;
  const totalPages = Math.ceil(projects.length / projectsPerPage);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = projects.slice(indexOfFirstProject, indexOfLastProject);

  return (
    <section id="projects" className="min-h-screen py-8 md:py-16 bg-gray-900">
      <div className="container mx-auto px-4">
        <motion.div className="text-center mb-12">
          <motion.h2 
            className="text-3xl md:text-6xl font-bold mb-4 text-center bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-secondary-400 py-2"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            Projelerim
          </motion.h2>
          
          <motion.div
            className="flex flex-col items-center justify-center gap-2"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
          >
            <p className="text-gray-400 text-sm md:text-base">
              Detaylı bilgi için projelere tıklayın
            </p>
            <motion.div
              animate={{
                y: [0, 5, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                ease: "easeInOut"
              }}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-5 w-5 text-purple-400"
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M19 14l-7 7m0 0l-7-7m7 7V3"
                />
              </svg>
            </motion.div>
          </motion.div>
        </motion.div>
        
        <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-2 md:gap-6 max-w-7xl mx-auto">
          {currentProjects.map((project, index) => (
            <motion.div
              key={project.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              onClick={() => window.location.href = `/project/${project.id}`}
              className="group relative bg-gray-800 rounded-xl overflow-hidden hover:shadow-2xl hover:shadow-purple-500/20 transition-all duration-300 cursor-pointer"
            >
              <div className="relative h-24 sm:h-36 overflow-hidden">
                <img 
                  src={project.image} 
                  alt={project.title} 
                  className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent opacity-60"></div>
              </div>
              
              <div className="p-2 md:p-4">
                <h3 className="text-xs md:text-lg font-bold mb-1 md:mb-2 text-white truncate">{project.title}</h3>
                <p className="text-[8px] md:text-sm text-gray-300 mb-1 line-clamp-2 hidden sm:block">{project.description}</p>
                <div className="flex flex-wrap gap-0.5">
                  {project.technologies.slice(0, 1).map((tech) => (
                    <span 
                      key={tech}
                      className="bg-purple-600/30 text-purple-300 px-1 py-0.5 rounded-full text-[8px] md:text-xs border border-purple-500/30"
                    >
                      {tech}
                    </span>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {totalPages > 1 && (
          <div className="flex justify-center gap-2 mt-6">
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentPage(index + 1)}
                className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 ${
                  currentPage === index + 1
                    ? 'bg-gradient-to-r from-blue-400 to-teal-400 text-white shadow-lg shadow-blue-500/30'
                    : 'bg-gray-800 text-gray-400 hover:bg-gray-700 hover:text-blue-400'
                }`}
              >
                {index + 1}
              </button>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Projects; 